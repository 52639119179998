import React from "react";
import { Link } from "gatsby";
import { kebabCase } from "lodash";
import classNames from "classnames/bind";
import styles from "./TagList.module.scss";

const cx = classNames.bind(styles);

const TagList = ({ tags, menu, section }) => {
  return (
    <nav className={cx(["tagNav", "pt-5"])}>
      <ul className={cx("tagUnordered")}>
        <li className={cx("tagLI")}>
          <Link
            to={`/${menu}/${section}`}
            className={cx("tagLink")}
            activeClassName={cx("active")}
          >
            모두보기
          </Link>
        </li>
        {tags
          .filter(({ edges }) =>
            edges[0].node.fields.slug.startsWith(`/${menu}`)
          )
          .sort((a, b) => b.totalCount - a.totalCount)
          .map(({ fieldValue }) => {
            return (
              <li key={fieldValue} className={cx("tagLI")}>
                <Link
                  to={`/${menu}/tags/${kebabCase(fieldValue)}`}
                  className={cx("tagLink")}
                  activeClassName={cx("active")}
                >
                  {fieldValue}
                </Link>
              </li>
            );
          })}
      </ul>
    </nav>
  );
};

export default TagList;
