import React from "react";
import { Link } from "gatsby";
import { Card } from "react-bootstrap";
import styles from "./TutorialCard.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const TutorialCard = ({ node }) => {
  return (
    <Card className={cx("card")}>
      <Link to={node.fields.slug} className={cx("cardLink")}>
        {node.frontmatter.image && (
          <Card.Img
            as="img"
            variant="top"
            src={node.frontmatter.image.childImageSharp.fluid.src}
            alt={node.frontmatter.title}
            height={160}
            className={cx("cardImg")}
          />
        )}
        <Card.Body className={cx("cardBody")}>
          <Card.Title className={cx("cardTitle")}>
            {node.frontmatter.title}
          </Card.Title>
          <Card.Text className={cx("cardMeta")}>
            <small>{node.frontmatter.date}</small>
          </Card.Text>
          <Card.Text
            className={cx("cardDesc")}
            dangerouslySetInnerHTML={{ __html: node.excerpt }}
          />
        </Card.Body>
      </Link>
    </Card>
  );
};

export default TutorialCard;
